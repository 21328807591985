import { mapGetters, mapMutations } from 'vuex';
export default {
  computed: {
    ...mapGetters('kyb', ['getSelectedBusiness']),
    ...mapGetters('account', ['getSelectedAccount'])
  },
  methods: {
    ...mapMutations('account', ['setSelectedAccount'])
  },
};
